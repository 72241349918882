/* eslint-disable */
/**
 *@exports Generic class
 */
export class Generic {
  public static LABELS: any = {
    vodafone: "Vodafone Admin",
    costCenter: "Business Group",
    group: "Country Group",
    local: "Geographic",
    tollFree: "Toll Free",
    national: "National",
    pending: "Pending",
    tollfree: "Toll Free",
    New: "New(Manually provisioned)",
  };

  public static LABELS_OPTIONS: any = {
    "Destination Number": "Destination Numbers",
    "Delivery Group": "Delivery Groups",
    "Call Flow Editor": "Call Flow",
    "SIP Trunks": "SIP Trunk",
  };

  public static ERROR_LABELS: any = {
    destinationNumberAssignError:
      "The Destination Number is already assigned to that Inbound Number. No changes have been made.",
    destinationNumberSelectError:
      "Please select Destination Number from the list",
    businessGroupAssignError:
      "The Business group is already assigned to that Inbound Number. No changes have been made.",
    businessGroupSelectError: "Please select Business group",
    deliveryGroupAssignError:
      "The Delivery group is already assigned to that Inbound Number. No changes have been made.",
    deliveryGroupSelectError: "Please select Delivery group from the list ",
    sipTrunkSelectError: "Please select SIP Trunk from the list ",
    sipGroupSelectError: "Please select SIP Group from the list ",
    callFlowAssignError:
      "The Call flow is already assigned to that Inbound Number. No changes have been made.",
    callFlowSelectError: "Please select Call flow from the list",
    checkInternetConnectionError: "Please check your network connection",
    BillingNotification: "Billing notification has failed",
    RegulatoryBundleNotification: "Bundle with this name already exists",
  };

  public static ValidationMsg: any = {
    invalidEmail: "Email format is invalid",
    alphanumeric: "Only spaces, letters, and numbers are allowed.",
    invalid: "Invalid characters.",
    invalidNumberFormat:
      "Please enter number with E.164 format (e.g. +441234678901)",
  };

  public static regDocs: any = {
    "Address Documents": [
      "Commercial register showing local address",
      "Utility bill",
      "Tax notice",
      "Rent receipt",
      "Title deed",
      "Commercial register showing Argentina address",
      "Excerpt from company book or registration showing matching address",
      "Excerpt from company book or registration showing Austrian address",
      "Excerpt from the commercial register",
    ],
    "Business Name": [
      "Commercial register",
      "Excerpt from company book or registration",
    ],
    "Commercial Registry Number": ["Commercial register"],
    "Name of Authorised Rep": [
      "Government-issued ID",
      "Passport",
      "Bank confirmation of identity",
    ],
    "Unified ID Code": [],
    "Seat of Address Registration": [],
  };

  public static dayOfWeek: any = [
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat",
    "Sun",
  ];

  public static tz: any = [
    { label: "Default", value: "Default" },
    { label: "(UTC-12:00) International Date Line West", value: "Etc/GMT+12" },
    { label: "(UTC-11:00) Midway Island, Samoa", value: "Pacific/Midway" },
    { label: "(UTC-10:00) Hawaii", value: "Pacific/Honolulu" },
    { label: "(UTC-09:00) Alaska", value: "US/Alaska" },
    {
      label: "(UTC-08:00) Pacific Time (US & Canada)",
      value: "America/Los_Angeles",
    },
    { label: "(UTC-08:00) Tijuana, Baja California", value: "America/Tijuana" },
    { label: "(UTC-07:00) Arizona", value: "US/Arizona" },
    {
      label: "(UTC-07:00) Chihuahua, La Paz, Mazatlan",
      value: "America/Chihuahua",
    },
    { label: "(UTC-07:00) Mountain Time (US & Canada)", value: "US/Mountain" },
    { label: "(UTC-06:00) Central America", value: "America/Managua" },
    { label: "(UTC-06:00) Central Time (US & Canada)", value: "US/Central" },
    {
      label: "(UTC-06:00) Guadalajara, Mexico City, Monterrey",
      value: "America/Mexico_City",
    },
    { label: "(UTC-06:00) Saskatchewan", value: "Canada/Saskatchewan" },
    {
      label: "(UTC-05:00) Bogota, Lima, Quito, Rio Branco",
      value: "America/Bogota",
    },
    { label: "(UTC-05:00) Eastern Time (US & Canada)", value: "US/Eastern" },
    { label: "(UTC-05:00) Indiana (East)", value: "US/East-Indiana" },
    { label: "(UTC-04:00) Atlantic Time (Canada)", value: "Canada/Atlantic" },
    { label: "(UTC-04:00) Caracas, La Paz", value: "America/Caracas" },
    { label: "(UTC-04:00) Manaus", value: "America/Manaus" },
    { label: "(UTC-04:00) Santiago", value: "America/Santiago" },
    { label: "(UTC-03:30) Newfoundland", value: "Canada/Newfoundland" },
    { label: "(UTC-03:00) Brasilia", value: "America/Sao_Paulo" },
    {
      label: "(UTC-03:00) Buenos Aires, Georgetown",
      value: "America/Argentina/Buenos_Aires",
    },
    { label: "(UTC-03:00) Greenland", value: "America/Godthab" },
    { label: "(UTC-03:00) Montevideo", value: "America/Montevideo" },
    { label: "(UTC-02:00) Mid-Atlantic", value: "America/Noronha" },
    { label: "(UTC-01:00) Cape Verde Is.", value: "Atlantic/Cape_Verde" },
    { label: "(UTC-01:00) Azores", value: "Atlantic/Azores" },
    {
      label: "(UTC+00:00) Casablanca, Monrovia, Reykjavik",
      value: "Africa/Casablanca",
    },
    {
      label: "(UTC+00:00) Dublin, Edinburgh, Lisbon, London",
      value: "Europe/London",
    },
    {
      label: "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
      value: "Europe/Amsterdam",
    },
    {
      label: "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
      value: "Europe/Belgrade",
    },
    {
      label: "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris",
      value: "Europe/Brussels",
    },
    {
      label: "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
      value: "Europe/Sarajevo",
    },
    { label: "(UTC+01:00) West Central Africa", value: "Africa/Lagos" },
    { label: "(UTC+02:00) Amman", value: "Asia/Amman" },
    {
      label: "(UTC+02:00) Athens, Bucharest, Istanbul",
      value: "Europe/Athens",
    },
    { label: "(UTC+02:00) Beirut", value: "Asia/Beirut" },
    { label: "(UTC+02:00) Cairo", value: "Africa/Cairo" },
    { label: "(UTC+02:00) Harare, Pretoria", value: "Africa/Harare" },
    {
      label: "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
      value: "Europe/Helsinki",
    },
    { label: "(UTC+02:00) Jerusalem", value: "Asia/Jerusalem" },
    { label: "(UTC+02:00) Minsk", value: "Europe/Minsk" },
    { label: "(UTC+02:00) Windhoek", value: "Africa/Windhoek" },
    { label: "(UTC+03:00) Kuwait, Riyadh, Baghdad", value: "Asia/Kuwait" },
    {
      label: "(UTC+03:00) Moscow, St. Petersburg, Volgograd",
      value: "Europe/Moscow",
    },
    { label: "(UTC+03:00) Nairobi", value: "Africa/Nairobi" },
    { label: "(UTC+03:00) Tbilisi", value: "Asia/Tbilisi" },
    { label: "(UTC+03:30) Tehran", value: "Asia/Tehran" },
    { label: "(UTC+04:00) Abu Dhabi, Muscat", value: "Asia/Muscat" },
    { label: "(UTC+04:00) Baku", value: "Asia/Baku" },
    { label: "(UTC+04:00) Yerevan", value: "Asia/Yerevan" },
    { label: "(UTC+04:30) Kabul", value: "Asia/Kabul" },
    { label: "(UTC+05:00) Yekaterinburg", value: "Asia/Yekaterinburg" },
    {
      label: "(UTC+05:00) Islamabad, Karachi, Tashkent",
      value: "Asia/Karachi",
    },
    {
      label: "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
      value: "Asia/Calcutta",
    },
    { label: "(UTC+05:30) Sri Jayawardenapura", value: "Asia/Colombo" },
    { label: "(UTC+05:45) Kathmandu", value: "Asia/Katmandu" },
    { label: "(UTC+06:00) Almaty, Novosibirsk", value: "Asia/Almaty" },
    { label: "(UTC+06:00) Astana, Dhaka", value: "Asia/Dhaka" },
    { label: "(UTC+06:30) Yangon (Rangoon)", value: "Asia/Rangoon" },
    { label: "(UTC+07:00) Bangkok, Hanoi, Jakarta", value: "Asia/Bangkok" },
    { label: "(UTC+07:00) Krasnoyarsk", value: "Asia/Krasnoyarsk" },
    {
      label: "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
      value: "Asia/Hong_Kong",
    },
    {
      label: "(UTC+08:00) Kuala Lumpur, Singapore",
      value: "Asia/Kuala_Lumpur",
    },
    { label: "(UTC+08:00) Irkutsk, Ulaan Bataar", value: "Asia/Irkutsk" },
    { label: "(UTC+08:00) Perth", value: "Australia/Perth" },
    { label: "(UTC+08:00) Taipei", value: "Asia/Taipei" },
    { label: "(UTC+09:00) Osaka, Sapporo, Tokyo", value: "Asia/Tokyo" },
    { label: "(UTC+09:00) Seoul", value: "Asia/Seoul" },
    { label: "(UTC+09:00) Yakutsk", value: "Asia/Yakutsk" },
    { label: "(UTC+09:30) Adelaide", value: "Australia/Adelaide" },
    { label: "(UTC+09:30) Darwin", value: "Australia/Darwin" },
    { label: "(UTC+10:00) Brisbane", value: "Australia/Brisbane" },
    {
      label: "(UTC+10:00) Canberra, Melbourne, Sydney",
      value: "Australia/Canberra",
    },
    { label: "(UTC+10:00) Hobart", value: "Australia/Hobart" },
    { label: "(UTC+10:00) Guam, Port Moresby", value: "Pacific/Guam" },
    { label: "(UTC+10:00) Vladivostok", value: "Asia/Vladivostok" },
    {
      label: "(UTC+11:00) Magadan, Solomon Is., New Caledonia",
      value: "Asia/Magadan",
    },
    { label: "(UTC+12:00) Auckland, Wellington", value: "Pacific/Auckland" },
    {
      label: "(UTC+12:00) Fiji, Kamchatka, Marshall Is.",
      value: "Pacific/Fiji",
    },
    { label: "(UTC+13:00) Nuku'alofa", value: "Pacific/Tongatapu" },
  ];

  public static timeSlotes: any = [
    "00:00",
    "00:05",
    "00:10",
    "00:15",
    "00:20",
    "00:25",
    "00:30",
    "00:35",
    "00:40",
    "00:45",
    "00:50",
    "00:55",
    "01:00",
    "01:05",
    "01:10",
    "01:15",
    "01:20",
    "01:25",
    "01:30",
    "01:35",
    "01:40",
    "01:45",
    "01:50",
    "01:55",
    "02:00",
    "02:05",
    "02:10",
    "02:15",
    "02:20",
    "02:25",
    "02:30",
    "02:35",
    "02:40",
    "02:45",
    "02:50",
    "02:55",
    "03:00",
    "03:05",
    "03:10",
    "03:15",
    "03:20",
    "03:25",
    "03:30",
    "03:35",
    "03:40",
    "03:45",
    "03:50",
    "03:55",
    "04:00",
    "04:05",
    "04:10",
    "04:15",
    "04:20",
    "04:25",
    "04:30",
    "04:35",
    "04:40",
    "04:45",
    "04:50",
    "04:55",
    "05:00",
    "05:05",
    "05:10",
    "05:15",
    "05:20",
    "05:25",
    "05:30",
    "05:35",
    "05:40",
    "05:45",
    "05:50",
    "05:55",
    "06:00",
    "06:05",
    "06:10",
    "06:15",
    "06:20",
    "06:25",
    "06:30",
    "06:35",
    "06:40",
    "06:45",
    "06:50",
    "06:55",
    "07:00",
    "07:05",
    "07:10",
    "07:15",
    "07:20",
    "07:25",
    "07:30",
    "07:35",
    "07:40",
    "07:45",
    "07:50",
    "07:55",
    "08:00",
    "08:05",
    "08:10",
    "08:15",
    "08:20",
    "08:25",
    "08:30",
    "08:35",
    "08:40",
    "08:45",
    "08:50",
    "08:55",
    "09:00",
    "09:05",
    "09:10",
    "09:15",
    "09:20",
    "09:25",
    "09:30",
    "09:35",
    "09:40",
    "09:45",
    "09:50",
    "09:55",
    "10:00",
    "10:05",
    "10:10",
    "10:15",
    "10:20",
    "10:25",
    "10:30",
    "10:35",
    "10:40",
    "10:45",
    "10:50",
    "10:55",
    "11:00",
    "11:05",
    "11:10",
    "11:15",
    "11:20",
    "11:25",
    "11:30",
    "11:35",
    "11:40",
    "11:45",
    "11:50",
    "11:55",
    "12:00",
    "12:05",
    "12:10",
    "12:15",
    "12:20",
    "12:25",
    "12:30",
    "12:35",
    "12:40",
    "12:45",
    "12:50",
    "12:55",
    "13:00",
    "13:05",
    "13:10",
    "13:15",
    "13:20",
    "13:25",
    "13:30",
    "13:35",
    "13:40",
    "13:45",
    "13:50",
    "13:55",
    "14:00",
    "14:05",
    "14:10",
    "14:15",
    "14:20",
    "14:25",
    "14:30",
    "14:35",
    "14:40",
    "14:45",
    "14:50",
    "14:55",
    "15:00",
    "15:05",
    "15:10",
    "15:15",
    "15:20",
    "15:25",
    "15:30",
    "15:35",
    "15:40",
    "15:45",
    "15:50",
    "15:55",
    "16:00",
    "16:05",
    "16:10",
    "16:15",
    "16:20",
    "16:25",
    "16:30",
    "16:35",
    "16:40",
    "16:45",
    "16:50",
    "16:55",
    "17:00",
    "17:05",
    "17:10",
    "17:15",
    "17:20",
    "17:25",
    "17:30",
    "17:35",
    "17:40",
    "17:45",
    "17:50",
    "17:55",
    "18:00",
    "18:05",
    "18:10",
    "18:15",
    "18:20",
    "18:25",
    "18:30",
    "18:35",
    "18:40",
    "18:45",
    "18:50",
    "18:55",
    "19:00",
    "19:05",
    "19:10",
    "19:15",
    "19:20",
    "19:25",
    "19:30",
    "19:35",
    "19:40",
    "19:45",
    "19:50",
    "19:55",
    "20:00",
    "20:05",
    "20:10",
    "20:15",
    "20:20",
    "20:25",
    "20:30",
    "20:35",
    "20:40",
    "20:45",
    "20:50",
    "20:55",
    "21:00",
    "21:05",
    "21:10",
    "21:15",
    "21:20",
    "21:25",
    "21:30",
    "21:35",
    "21:40",
    "21:45",
    "21:50",
    "21:55",
    "22:00",
    "22:05",
    "22:10",
    "22:15",
    "22:20",
    "22:25",
    "22:30",
    "22:35",
    "22:40",
    "22:45",
    "22:50",
    "22:55",
    "23:00",
    "23:05",
    "23:10",
    "23:15",
    "23:20",
    "23:25",
    "23:30",
    "23:35",
    "23:40",
    "23:45",
    "23:50",
    "23:55",
    "24:00",
  ];

  public static variables: any = {
    global: "Global",
    transient: "Transient",
    persistent: "Persistent",
  };

  static readonly ADMIN_ON_DEMAND = "Administrator on Demand";
  static readonly ADMINISTRATOR = "Administrator";
  static readonly CUSTOMER_ADMINISTRATOR = "Customer Administrator";

  public static numberTypesMapping: any = {
    geographic: "Local",
    national: "National",
    tollfree: "Toll Free",
    local: "Geographic",
    National: "national",
    Tollfree: "toll-free",
    Geographic: "local",
    "Toll Free": "toll-free",
    "toll-free": "Toll Free",
  };

  public static UNABLE_TO_LOAD = "Unable to load";
}
